import React, { FunctionComponent, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PortableText } from "../../types/SanityTypes";
import { RichTextSerializers } from "../../utils/richTextSerializers";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

type TextBlockProps = {
  blockType: {
    name: string;
  };
  ctaLink: {
    asset: {
      asset: {
        url: string;
      };
    };
    slug: {
      current: string;
    }
  };
  _rawTextBody: PortableText;
  ctaLabel: string;
  headline: {
    primaryText: string;
    secondaryText: string;
    tertiaryText: string;
  };
  theme: {
    name: string;
  };
  fontColor: {
    value: string;
    title: string;
  };
};

function getAlignemntCssClass(blockType: any) {
  if (blockType?.name === "Right aligned") {
    return "text-right";
  }

  if (blockType?.name === "Center aligned" || blockType?.name === "Teal Gradient Pattern") {
    return "text-center";
  }

  return "text-left";
}

function getGradientPattern(blockType: any) {
  if (blockType?.name === "Teal Gradient Pattern") {
    return "theme-teal";
  }

  return "other";
}

function setGradientCSSClass(colorObj: { value: string; title: string }) {
  const title = colorObj?.title.toLowerCase();
  const color = title || "orange-yellow";
  return `rx-gradient--${color}`;
}

const TextBlockV2: FunctionComponent<TextBlockProps> = ({
  _rawTextBody,
  headline,
  ctaLabel,
  ctaLink,
  theme,
  fontColor,
  blockType
}) => {
  let isPDF = false;
  if (ctaLink) {
    isPDF = ctaLink.asset?.asset?.url.includes("pdf");
  }

  const createThemeClassName = (className: string) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  
const [btnPath, setBtnPath] = useState('');
useEffect(() => {
  if (ctaLink?.asset) {
    setBtnPath(ctaLink?.asset?.asset?.url)
  } else if (ctaLink?.slug && typeof window !== "undefined") {
    setBtnPath(`${window.location.origin}/${ctaLink?.slug?.current}`)
  }
}, [ctaLink]);

  return (
    <section
      className={`rx-section rx-section--theme-secondary rx-section--textblockv2 ${getGradientPattern(
        blockType
      )} ${getAlignemntCssClass(blockType)} ${createThemeClassName(theme?.name)}`}
    >
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className={`rx-section--textblockv2-content`}>
              {headline?.primaryText && (
                <h2 className={`rx-heading-primary ${setGradientCSSClass(fontColor)}`}>{headline?.primaryText}</h2>
              )}
              {headline?.secondaryText && (
                <h3 className={`rx-heading-secondary ${setGradientCSSClass(fontColor)}`}>{headline?.secondaryText}</h3>
              )}
              {headline?.tertiaryText && (
                <h3 className={`rx-heading-secondary ${setGradientCSSClass(fontColor)}`}>{headline?.tertiaryText}</h3>
              )}
              {_rawTextBody && (
                <div
                  className={`rx-section--textblockv2-copy ${
                    !headline?.primaryText && !headline?.secondaryText && !headline?.tertiaryText ? "only-element" : ""
                  }`}
                >
                  <BlockContent blocks={_rawTextBody} serializers={RichTextSerializers()} />
                </div>
              )}
            </div>
            {ctaLabel && ctaLink ? (
              <div className="rx-c-textBlock-cta">
                <a
                  className="btn-primary"
                  rel="noopener noreferrer"
                  href={btnPath}
                  download={isPDF ? true : false}
                >
                  {ctaLabel}
                </a>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TextBlockV2;
